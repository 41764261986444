import { isEmpty, get, filter, isNil, capitalize as capitalizeLodash } from 'lodash'

export const capitalize = (string, separator = ' ') => string && string.split(separator).map(capitalizeLodash).join(separator)

export function concatString(...args) {
  return (separator = ' ') => {
    const restArgs = filter(args, attribute => !isNil(attribute) && !isEmpty(attribute))
    if (restArgs.length > 1) {
      return restArgs.join(separator)
    }
    return get(restArgs, '0')
  }
}

export function capitalizeFirstLetter(str) {
  if (typeof str !== 'string') return ''
  if (str.length === 1) return str.toUpperCase()
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}
