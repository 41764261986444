import React from 'react'
import cn from 'classnames'
import { string, shape, arrayOf } from 'prop-types'

import { FixedSizeList } from 'react-window'

import styles from './ListPiecesNecessaires.module.css'

ListPiecesNecessaires.propTypes = {
  pieces: arrayOf(shape({
    categorie: string,
    dateValidation: string.isRequired,
    libelle: string.isRequired,
  })).isRequired,
}

export function ListPiecesNecessaires({ pieces }) {
  return !pieces?.length ? null : (
    <div className={cn(styles.listPieces__card, styles.listPieces__cardComplementaire)}>
      <div className={styles.listPieces__cardHeader}>
        <div className={styles.listPieces__enteteComplementaire}>
          <div className={styles.listPieces__title}>
            <span className={styles.listPieces__titleLibelle}>Pièces validées</span>
          </div>
        </div>
      </div>
      <FixedSizeList
        className={styles.listPiecesNecessaires}
        height={pieces.length * 50}
        itemCount={pieces.length}
        itemSize={50}
      >
        {({ index, style }) => {
          const { libelle, dateValidation } = pieces[index]
          return (
            <div
              aria-label={`Pièce nécessaire ${index + 1}`}
              style={style}
              className={cn(styles.listPiecesNecessaires__line, {
                [styles.listPiecesNecessaires__lineStriped]: index % 2 === 0,
              })}
            >
              <span>{libelle}</span>
              <span className={styles.listPiecesNecessaires__lineStatut}>{`Validé le ${dateValidation}`}</span>
            </div>
          )
        }}
      </FixedSizeList>
    </div>
  )
}
