export const GAMME_OPTIONS = [
  { label: 'Hypothécaire', value: 'hypothecaire' },
  { label: 'Sans garantie', value: 'sans_garantie' },
]

export const REGIME = [
  { label: 'LS2', value: 'LS2' },
  { label: 'LCC', value: 'LCC' },
]
export enum DetailProduitKey {
  TRESORIE = 'TRESORIE',
  HONORAIRES_INTERMEDIAIRES_CREDIT = 'HONORAIRES_INTERMEDIAIRES_CREDIT',
  FRAIS_DOSSIER_BANCAIRE = 'FRAIS_DOSSIER_BANCAIRE',
  EVALUATION_FRAIS_ACTE = 'EVALUATION_FRAIS_ACTE',
  MONTANT_PRETS_RACHETER = 'MONTANT_PRETS_RACHETER',
  APPORT = 'APPORT',
  MONTANT_OPERATION = 'MONTANT_OPERATION',
}
