import React from 'react'
import { string } from 'prop-types'

import { DossierInterlocuteur, Panel, Separator } from '@core/partenaire-ui'
import { trackOnChange } from '@core/analytics'
import { useIdentite } from '@core/partenaire-profil'

import { useEmprunteursAssurance } from '../../Assurances/Assurances.hooks'
import { Information } from './Information'
import { usePanel } from './DetailPanel.hooks'
import { DetailPanelLink } from './DetailPanelLink'

import styles from './DetailPanel.module.css'

DetailPanel.propTypes = {
  id: string.isRequired,
}

export function DetailPanel({ id }) {
  const { identifiant } = useIdentite()
  trackOnChange('Detail dossier', identifiant)

  const { interlocuteur } = useEmprunteursAssurance(id)
  const { loading, links, replaceReference } = usePanel(id)
  return (
    <Panel
      className={styles.detailPanel}
      aria-label="menu de la page détail"
    >
      {loading || !links ? null :
        (
          <div>
            <Information id={id} />
            <DossierInterlocuteur nom={interlocuteur?.nom} email={interlocuteur?.email} telephone={interlocuteur?.telephone} />
            <Separator className={styles.separator} />
            {links.map(({ linkPattern, label }) => (
              <DetailPanelLink key={label} linkPattern={linkPattern} label={label} replaceReference={replaceReference} />
            ))}
          </div>
        )}
    </Panel>
  )
}
