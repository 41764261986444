import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { FixedSizeList } from 'react-window'
import { array, func, string } from 'prop-types'

import { Chevron, Delete } from '@mmb/ui-components'
import { WarningPopIn } from '@partenaire/frontend-ui'
import { PopoverCategorie } from '@core/partenaire-ui'
import { toast } from '@core/ui'
import { formatCondenseDateWithHour } from '@core/common'

import { DocumentUI } from '../../../model'

import styles from './DocumentList.module.css'

DocumentList.propTypes = {
  documentUIs: array.isRequired,
  categorieLabel: string.isRequired,
  popoverItems: array,
  deleteDocument: func,
  getDownloadDocumentURL: func,
  goBackFunction: func,
}

DocumentList.defaultProps = {
  getDownloadDocumentURL: () => {},
  goBackFunction: () => {},
  deleteDocument: () => {},
  popoverItems: [],
}

export function DocumentList({ documentUIs, categorieLabel, popoverItems, goBackFunction, deleteDocument, getDownloadDocumentURL }) {
  const nbPieces = documentUIs.length
  const [list, setList] = useState<DocumentUI[]>(documentUIs)
  const handleDelete = async (documentId: string) => {
    deleteDocument(documentId)
      .then(() => toast({ message: 'Le document a été supprimé.', type: 'SUCCESS' }))
      .catch(() => toast({ message: 'La suppression a échouée.', type: 'ERROR' }))
  }

  useEffect(() => {
    if (documentUIs && documentUIs.length > 0) {
      setList(documentUIs)
    }
  }, [documentUIs])

  const totalCount = list && list.length

  return (
    <div role="rowgroup" aria-label={`Liste des pièces de la catégorie ${categorieLabel}`}>
      <div className={cn(styles.list__line, styles.list__header)}>
        <Chevron className={styles.list__headerChevron} aria-label="Revenir à l'envoi de pièces" onClick={goBackFunction} />
        <span className={styles.list__headerTitle} role="columnheader">{categorieLabel}</span>
        <PopoverCategorie label={categorieLabel} items={popoverItems} />
      </div>

      {nbPieces && (
        <FixedSizeList
          height={totalCount * 50}
          itemCount={totalCount}
          itemSize={50}
        >
          {({ index, style }) => {
            const { id: documentId, filename, dateCreation } = list[index].props
            return (
              <div
                aria-label={`Pièce ${index + 1} ${filename}`}
                style={style}
                className={cn(styles.list__line, { [styles.list__lineStriped]: index % 2 === 0 })}
              >
                <a
                  className={styles.list__name}
                  href={getDownloadDocumentURL(documentId)}
                  target="_blank"
                  referrerPolicy="origin"
                  role="button"
                  rel="noreferrer"
                >
                  {filename}
                </a>
                {!!dateCreation && (
                  <span
                    className={styles.list__date}
                    aria-label={`Date d'envoi de la pièce ${index + 1}`}
                  >{formatCondenseDateWithHour(dateCreation)}
                  </span>
                )}
                {documentId && (
                  <WarningPopIn
                    onConfirm={() => handleDelete(documentId)}
                    label={`Suppression du document ${documentId}`}
                    title="Suppression d'un document"
                    warningLine="Êtes-vous sûr de vouloir supprimer le document ?"
                    warningLineBold="Cette action est définitive."
                  >
                    <Delete />
                  </WarningPopIn>
                )}
              </div>
            )
          }}
        </FixedSizeList>
      )}
    </div>
  )
}
