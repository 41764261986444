import React, { useEffect } from 'react'

import { number, bool, object } from 'prop-types'
import { Input, formatNumberCurrency } from '@mmb/ui-components'

import { Gamme, HorsNormeCodeEnum } from '@partenaire/common'
import classNames from 'classnames'
import styles from './ResultatSimulationRapide.module.css'
import { NON_VALIDE_SIMULATION_STATUS, SimulationStatus } from '../Simulation/const'
import { SimulationCard } from '../Simulation/SimulationCard'
import { DetailProduitKey } from './const'
import { hasErrorForName } from '../../utils'
import { BannerCard } from '../Simulation/BannerCard'
import { HorsNormeCardErrors } from '../Simulation/Header/CardErrors/HorsNormeCardErrors'
import { NonValideCardErrors } from '../Simulation/Header/CardErrors/NonValideCardErrors'
import { SaisieCardErrors } from '../Simulation/Header/CardErrors/SaisieCardErrors'
import { ChangeGammeProps } from '../Gamme'

ResultatSimulationRapide.propTypes = {
  simulation: object.isRequired,
  loading: bool.isRequired,
  restantDu: number.isRequired,
  gammeProps: object.isRequired,
}

function getValueByKeyDetailProduit(key, simulation) {
  const item = simulation?.produit?.detail?.find(e => e.key === key)
  return item ? item.value : 0
}

function getErrorHorsNormeByKey(key, simulation) {
  const item = simulation?.errors?.horsNorme?.find(e => e.field === key)
  return item ? item.args : 0
}

function formatCurrencyByKey(key: DetailProduitKey, simulation: any) {
  const value = getValueByKeyDetailProduit(key, simulation) ?? 0
  return value ? formatNumberCurrency({ number: value }) : '-'
}

function Header({ simulation, gammeProps } : { simulation: any, gammeProps: ChangeGammeProps }) {
  const simulationErrors = simulation?.errors ?? {}
  const hasError = simulation?.statut === SimulationStatus.HORS_NORME
    || simulationErrors?.horsNorme?.length || simulationErrors?.nonValide?.errors?.length || simulationErrors?.errorSaisie?.length

  if (!hasError || simulation?.statut === SimulationStatus.INTERNAL_ERROR) {
    return null
  }
  return (
    <BannerCard
      title="Votre simulation présente des critères hors-normes."
      hasError
    >
      {simulationErrors.horsNorme && <HorsNormeCardErrors errors={simulationErrors.horsNorme} gammeProps={gammeProps} />}
      {simulationErrors?.nonValide && (<NonValideCardErrors
        products={simulationErrors.nonValide?.product}
        errors={simulationErrors.nonValide?.errors}
        gammeProps={gammeProps}
      />)}
      {simulationErrors.errorSaisie?.[0] && <SaisieCardErrors error={simulationErrors.errorSaisie?.[0]} />}
    </BannerCard>
  )
}

function Body({ simulation, simulationErrors, restantDu } : { simulation: any, simulationErrors: any, restantDu: number }) {
  const hasHorsNormeToShow = Boolean(simulation?.errors?.horsNorme?.length)

  if (!hasHorsNormeToShow) {
    if (simulation?.statut === SimulationStatus.INTERNAL_ERROR) {
      return <SimulationCard state={SimulationStatus.INTERNAL_ERROR} />
    }

    if (simulation?.statut === SimulationStatus.NON_ELIGIBLE) {
      return <SimulationCard state={SimulationStatus.NON_ELIGIBLE} />
    }

    if (NON_VALIDE_SIMULATION_STATUS.includes(simulation?.statut)) {
      return <SimulationCard state={SimulationStatus.NON_VALIDE} />
    }
  }

  return (
    <div className={styles.simulation_rapide} id="simulation">
      <div className={styles.simulation_rapide__columns}>
        <div className={styles.simulation_rapide__column_1}>
          <div className={styles.simulation_rapide__title}> Diagnostic </div>
          <Input
            fieldClassName={classNames(
              styles.simulation_rapide__input,
              { [styles.inputError]: hasErrorForName(HorsNormeCodeEnum.TAUX_USURE, simulationErrors?.horsNorme) },
            )}
            hasError={hasErrorForName(HorsNormeCodeEnum.TAUX_USURE, simulationErrors?.horsNorme)}
            labelClassName={styles.simulation_rapide__left_column}
            type="text"
            name="taeg"
            label={`TAEG (${simulation?.diagnostic?.tauxUsure?.toFixed(2) ?? 0} %)`}
            value={`${simulation?.diagnostic?.taeg ?? 0} %`}
            readOnly
          />
          <Input
            fieldClassName={styles.simulation_rapide__input}
            type="text"
            name="endettement_avant"
            label="Endettement avant"
            value={`${simulation?.diagnostic?.endettementAvant?.toFixed(2) ?? 0} %`}
            readOnly
          />
          <Input
            hasError={hasErrorForName(HorsNormeCodeEnum.ENDETTEMENT_APRES, simulationErrors?.horsNorme)}
            fieldClassName={classNames(
              styles.simulation_rapide__input,
            )}
            type="text"
            name="endettement_apres"
            label="Endettement après"
            value={`${simulation?.diagnostic?.endettementApres?.toFixed(2) ?? 0} %`}
            readOnly
          />
          <Input
            hasError={hasErrorForName(HorsNormeCodeEnum.RESTE_A_VIVRE_APRES, simulationErrors?.horsNorme)}
            fieldClassName={classNames(
              styles.simulation_rapide__input,
            )}
            type="text"
            name="reste_a_vivre"
            label="Reste à vivre"
            value={formatNumberCurrency({ number: simulation?.diagnostic?.resteAVivre })}
            readOnly
          />
        </div>
        <div className={styles.simulation_rapide__column_2}>
          <div
            className={classNames(
              styles.simulation_rapide__subcolumns,
              styles.simulation_rapide__product_gamme__unsecured,
              { [styles.simulation_rapide__product_gamme__secured]: simulation?.produit?.gamme === Gamme.SECURED },
            )}
          >
            <div className={styles.simulation_rapide__subcolumn_1}>
              <div className={styles.simulation_rapide__product_gamme}>
                <div className={styles.simulation_rapide__product_name}>
                  {simulation?.produit?.nom ?? ''}
                </div>
                <div className={styles.simulation_rapide__product_offre}>
                  {simulation?.produit?.gamme === Gamme.SECURED ? 'Offre en hypothécaire' : 'Offre sans garantie'}
                  {simulation?.produit?.regime ? `- ${simulation?.produit?.regime}` : ''}
                </div>
              </div>
            </div>
            <div className={styles.simulation_rapide__subcolumn_2}>
              <div
                className={classNames(
                  styles.simulation_rapide__product_montant,
                  { [styles.labelError]: simulation?.produit?.montantFinancement < Number(
                    getErrorHorsNormeByKey(HorsNormeCodeEnum.MONTANT_FINANCE, simulation)?.min)
                      || Number(getErrorHorsNormeByKey(HorsNormeCodeEnum.MONTANT_FINANCE, simulation)?.max) > simulation?.produit?.montantFinancement },
                )}
              >
                {formatNumberCurrency({ number: simulation?.produit?.montantFinancement })}
              </div>
              <div
                className={classNames(
                  styles.simulation_rapide__product_duree,
                  { [styles.labelError]: simulation?.produit?.dureeFinancement <= Number(
                    getErrorHorsNormeByKey(HorsNormeCodeEnum.DUREE_FINANCEE, simulation)?.min)
                      && simulation?.produit?.dureeFinancement >= Number(getErrorHorsNormeByKey(HorsNormeCodeEnum.DUREE_FINANCEE, simulation)?.max) },
                )}
              >
                {simulation?.produit?.dureeFinancement ?? 0} mois
              </div>
            </div>
            <div className={styles.simulation_rapide__subcolumn_3}>
              <div className={styles.simulation_rapide__product_taux_name}>
                Taux
              </div>
              <div className={styles.simulation_rapide__product_taux_pourcentage}>
                {simulation?.produit?.taux?.toFixed(2) ?? 0} %
              </div>
            </div>
          </div>
          <div className={styles.simulation_rapide__echeance_variation}>
            <div className={styles.simulation_rapide__echeance_variation_column}>
              <div className={styles.simulation_rapide__echeance_variation_title}> Echéance </div>
              <div className={styles.simulation_rapide__echeance_variation_text}> {formatNumberCurrency({ number: simulation?.produit?.echeance })}/mois</div>
            </div>
            <div className={styles.simulation_rapide__echeance_variation_column}>
              <div className={styles.simulation_rapide__echeance_variation_title}> Variation </div>
              <div className={styles.simulation_rapide__echeance_variation_text}>
                {formatNumberCurrency({ number: simulation?.produit?.variation })}/mois
              </div>
            </div>
          </div>
          <div className={styles.simulation_rapide__lignes}>
            <div className={styles.simulation_rapide__lignes__column_1}>
              <div className={styles.simulation_rapide__lignes__column_text_1}> Trésorerie </div>
              <div className={styles.simulation_rapide__lignes__column_text_2}> Honoraires intermédiaires de crédit </div>
              <div className={styles.simulation_rapide__lignes__column_text_1}> Frais de dossier bancaire </div>
              <div className={styles.simulation_rapide__lignes__column_text_2}> Montant du (des) prêt(s) à racheter </div>
              <div className={styles.simulation_rapide__lignes__column_text_1}> Évaluation des frais d'acte </div>
              <div className={styles.simulation_rapide__lignes__column_text_2}> Montant de l'opération </div>
            </div>
            <div className={styles.simulation_rapide__lignes__column_2}>
              <div
                className={classNames(
                  styles.simulation_rapide__lignes__column_text_1_tresorerie,
                  { [styles.labelError]: hasErrorForName([
                    HorsNormeCodeEnum.MONTANT_TRESORERIE,
                    HorsNormeCodeEnum.PLAFOND_TRESORERIE_POURCENT], simulationErrors?.horsNorme) },
                )}
              >
                {formatCurrencyByKey(DetailProduitKey.TRESORIE, simulation)}
              </div>
              <div className={classNames(styles.simulation_rapide__lignes__column_text_2)}>
                {formatCurrencyByKey(DetailProduitKey.HONORAIRES_INTERMEDIAIRES_CREDIT, simulation)}
              </div>
              <div className={styles.simulation_rapide__lignes__column_text_1}>
                {formatCurrencyByKey(DetailProduitKey.FRAIS_DOSSIER_BANCAIRE, simulation)}
              </div>
              <div className={styles.simulation_rapide__lignes__column_text_2}>
                {formatNumberCurrency({ number: restantDu })}
              </div>
              <div className={styles.simulation_rapide__lignes__column_text_1}>
                {simulation.produit.gamme === Gamme.UNSECURED ? 'N/A' : formatCurrencyByKey(DetailProduitKey.EVALUATION_FRAIS_ACTE, simulation)}
              </div>
              <div
                className={classNames(
                  styles.simulation_rapide__lignes__column_text_2,
                  { [styles.labelError]: simulation?.produit?.montantFinancement < Number(
                    getErrorHorsNormeByKey(HorsNormeCodeEnum.MONTANT_FINANCE, simulation)?.min)
                        || Number(getErrorHorsNormeByKey(HorsNormeCodeEnum.MONTANT_FINANCE, simulation)?.max) > simulation?.produit?.montantFinancement },
                )}
              >
                {formatNumberCurrency({ number: simulation?.produit?.montantFinancement })}
              </div>
            </div>
          </div>
        </div>

      </div>
      <p>
        Cette simulation n'a aucune valeur contractuelle. Seule une offre de
        financement régulièrement émise par le prêteur pourrait l'engager, après
        étude approfondie de la situation du client. Les montants sont indiqués
        hors assurance emprunteur facultative pour la gamme My Simply One.
      </p>
    </div>
  )
}

export function ResultatSimulationRapide({ simulation, loading, restantDu, gammeProps }) {
  const simulationErrors = simulation?.errors

  useEffect(() => {
    if (!loading) document?.getElementById('simulation')?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [loading])

  return (
    <>
      <Header simulation={simulation} gammeProps={gammeProps} />
      <Body simulation={simulation} simulationErrors={simulationErrors} restantDu={restantDu} />
    </>
  )
}

