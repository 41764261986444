import React from 'react'
import classnames from 'classnames'

import { Card, formatNumberCurrency } from '@mmb/ui-components'
import { HorsNormeCodeEnum } from '@partenaire/common'

import { hasErrorForName } from '../../../../utils'

import styles from './Body.module.css'
import { useSimulation } from '../../../Contexts'
import { BodyWithPaliers } from './BodyWithPaliers'
import { BodyPalier } from './BodyPalier'
import { BodyAssurance } from './BodyAssurance'

const DETAIL_LABELS = {
  TRESORIE: { label: 'Trésorerie', error: [HorsNormeCodeEnum.MONTANT_TRESORERIE, HorsNormeCodeEnum.PLAFOND_TRESORERIE_POURCENT] },
  HONORAIRES_INTERMEDIAIRES_CREDIT: { label: 'Honoraires intermédiaires de crédit' },
  FRAIS_DOSSIER_BANCAIRE: { label: 'Frais de dossier bancaire' },
  EVALUATION_FRAIS_ACTE: { label: 'Évaluation des frais d\'acte' },
  MONTANT_PRETS_RACHETER: { label: 'Montant du (des) prêt(s) à racheter' },
  APPORT: { label: 'Apport personnel' },
  MONTANT_OPERATION: { label: 'Montant de l\'opération' },
}

export function Body() {
  const [{ product, simulationErrors, assurances }] = useSimulation()
  const hasSecured = product.gamme === 'SECURED'
  return (
    <div className={styles.simulation__produit__body}>
      {product?.paliers && product?.paliers.length >= 2 ? (
        <BodyWithPaliers paliers={product?.paliers} hasSecured={hasSecured} />
      ) : (
        <BodyPalier product={product} hasSecured={hasSecured} />
      )}
      <Card>
        <div
          role="table"
          // eslint-disable-next-line react/no-unknown-property
          area-describedby="simulation_produit_table_detail"
          aria-label="Details du produit proposer par la simulation"
          className={styles.simulation__produit__body__table}
        >
          {(product?.detail || []).map(({ key, value }, index) => (
            <div
              aria-label={`${DETAIL_LABELS[key]?.label} proposé par la simulation`}
              key={key}
              className={classnames(styles.simulation__produit__body__row, {
                [styles.simulation__produit__body__row__stripped]: index % 2 !== 0,
              })}
            >
              <span>{DETAIL_LABELS[key]?.label}</span>
              <span
                className={classnames({
                  [styles.simulation__produit__body__row__error]: hasErrorForName(DETAIL_LABELS[key]?.error, simulationErrors?.horsNorme),
                })}
              >
                {value && formatNumberCurrency({ number: value })}
              </span>
            </div>
          ))}
        </div>
      </Card>
      {!hasSecured && (
        <Card>
          <div className={styles.simultaion__assurances__body}>
            {assurances.map((assurance, idx) => (<BodyAssurance key={`${idx + 1}`} assurance={assurance} />))}
          </div>
        </Card>
      )}
    </div>
  )
}
