import React, { FunctionComponent } from 'react'
import { SimulationFieldHorsNorme } from '../../../../model'

import styles from '../Header.module.css'
import { getSimulationHorsNormeMessage } from '../../../../utils'
import { ChangeGammeProps } from '../../../Gamme'


interface HorsNormeCardErrorsProps {
  errors: SimulationFieldHorsNorme[]
  gammeProps: ChangeGammeProps
}

export const HorsNormeCardErrors: FunctionComponent<HorsNormeCardErrorsProps> = ({ errors, gammeProps }) => {
  return (
    <ul>
      {errors.map(error => (
        <li key={error.field} aria-label={error.field} className={styles.header__contentItem}>{getSimulationHorsNormeMessage(error, gammeProps)}</li>
      ))}
    </ul>
  )
}
