import React from 'react'
import Pluralize from 'react-pluralize'
import { string, bool, number } from 'prop-types'

import { Spinner } from '@mmb/ui-components'

import styles from './StatutPiece.module.css'
import { Statut } from '../../Statut'

StatutPiece.propTypes = {
  label: string.isRequired,
  loading: bool.isRequired,
  hasPieces: bool.isRequired,
  nbPieces: number.isRequired,
  progressedFiles: number.isRequired,
  nbLoadingFiles: number.isRequired,
  showNoDoc: bool.isRequired,
}

export function StatutPiece({ label, loading, hasPieces, nbPieces, progressedFiles, nbLoadingFiles, showNoDoc }) {
  const ariaLabel = `Nombre de documents de la catégorie ${label}`
  return (
    <div className={styles.statutPiece}>
      {!loading && nbLoadingFiles === 0 && (
        hasPieces ? (
          <Statut
            className={styles.statutPiece__nbDocuments}
            label={ariaLabel}
          >
            <div>
              {nbPieces}
            </div>
            &nbsp;
            <Pluralize singular="document" count={nbPieces} showCount={false} />
          </Statut>
        ) : showNoDoc && <div aria-label={ariaLabel}>Aucun document</div>
      )}
      {nbLoadingFiles > 0 && (
        <Statut className={styles.statutPiece__enCours} icon={Spinner} label="Chargement de fichier(s) en cours">
          En cours de chargement ({Math.min(progressedFiles + 1, nbLoadingFiles)}/{nbLoadingFiles})
        </Statut>
      )}
    </div>
  )
}
