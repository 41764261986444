
import { string } from 'prop-types'
import React from 'react'
import { Bullet } from '@mmb/ui-components'

import styles from './DossierInterlocuteur.module.css'

DossierInterlocuteur.propTypes = {
  nom: string,
  email: string,
  telephone: string,
}

DossierInterlocuteur.defaultProps = {
  nom: '',
  email: '',
  telephone: '',
}

export function DossierInterlocuteur({ nom, email, telephone }) {
  if (!nom) {
    return (
      <div />
    )
  }

  return (
    <div className={styles.dossierInterlocuteur__container}>
      <div className={styles.dossierInterlocuteur__title}>
        <Bullet className={styles.dossierInterlocuteur__bullet} />
        <div className={styles.dossierInterlocuteur__titleText}> PRIS EN CHARGE PAR </div>
      </div>
      <div className={styles.dossierInterlocuteur__info}> {nom} </div>
      <div className={styles.dossierInterlocuteur__info}> {email} </div>
      <div className={styles.dossierInterlocuteur__info}> {telephone} </div>
    </div>
  )
}
