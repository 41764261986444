import { gql, useLazyQuery } from '@core/graphql'

export const GET_SIMULATION_RAPIDE = gql`
  query getSimulationRapide($demandeSimulationRapide: partenaire_DemandeSimulationRapide!) {
    partenaire_simulationRapide(demandeSimulationRapide: $demandeSimulationRapide) {
      statut
      errors {
        horsNorme {
          field
          args {
            min
            taux
            max
          }
        }
        nonValide {
          products
          errors {
            field
            args {
              min
              max
              taux
            }
          }
        }
        errorSaisie {
          field
          args {
            min
            max
            taux
          }
        }
      }
      produit {
        nom
        gamme
        regime
        montantFinancement
        montantApport
        montantOperation
        dureeFinancement
        taux
        seuilImmo
        echeance
        variation
        variationWithAssurance
        detail {
          key
          value
        }
        paliers {
          echeance
          variation
          duree
          debut
          fin
          resteAVivre
          variationWithAssurance
        }
      }
      assurances {
        statut
        nom
        montantEcheance
        prenom
        role
        type
        couverture
        quotite
      }
      diagnostic {
        hasPriority
        taeg
        ratioHypo
        tauxUsure
        expertiseImmo
        endettementAvant
        endettementApres
        resteAVivre
      }
    }
}
`

export function useSimulationRapide() {
  const [getSimulationRapide, {
    error,
    data,
    loading,
  }] = useLazyQuery(GET_SIMULATION_RAPIDE)
  return { getSimulationRapide, simulation: data?.partenaire_simulationRapide, loading, error }
}
