export enum DimensionCodeEnum {
    ANCIENNETE_RACHAT_HYPO = 'ANCIENNETE_RACHAT_HYPO',
    ANCIENNETE_RACHAT_NON_HYPO = 'ANCIENNETE_RACHAT_NON_HYPO',
    ANCIEN_CLIENT = 'ANCIEN_CLIENT',
    CHAMP_RESTRUCTURATION = 'CHAMP_RESTRUCTURATION',
    CLIENT_CIF = 'CLIENT_CIF',
    CRD_RACHAT_NON_HYPO = 'CRD_RACHAT_NON_HYPO',
    DEPARTEMENT_BIEN_EN_GARANTIE = 'DEPARTEMENT_BIEN_EN_GARANTIE',
    DEPARTEMENT_RESIDENCE_PRINCIPALE = 'DEPARTEMENT_RESIDENCE_PRINCIPALE',
    DUREE_FINANCEE = 'DUREE_FINANCEE',
    IMMO_CONSO_POURCENT = 'IMMO_CONSO_POURCENT',
    MONTANT_TRESORERIE = 'MONTANT_TRESORERIE',
    NATURE_BIEN_EN_GARANTIE = 'NATURE_BIEN_EN_GARANTIE',
    NB_ADULTES_FOYER = 'NB_ADULTES_FOYER',
    NB_CREDITS_REPRIS = 'NB_CREDITS_REPRIS',
    NB_ENFANTS_A_CHARGE = 'NB_ENFANTS_A_CHARGE',
    NB_IMPAYE_CT = 'NB_IMPAYE_CT',
    NB_IMPAYE_IMMO = 'NB_IMPAYE_IMMO',
    PRET_NON_REPRIS = 'PRET_NON_REPRIS',
    PROCESSUS_ANALYSE = 'PROCESSUS_ANALYSE',
    SAISIE_RECOUVREMENT = 'SAISIE_RECOUVREMENT',
    TYPE_ACTIVITE_CLIENT = 'TYPE_ACTIVITE_CLIENT',
    VALEUR_BIEN_EN_GARANTIE = 'VALEUR_BIEN_EN_GARANTIE',
    ZONE_BIEN = 'ZONE_BIEN',
    ENDETTEMENT_AVANT = 'ENDETTEMENT_AVANT',
    ELIGIBILITE_BIEN_EN_GARANTIE = 'ELIGIBILITE_BIEN_EN_GARANTIE',
    ELIGIBILITE_CODE_APR = 'ELIGIBILITE_CODE_APR',
    ELIGIBILITE_RESTRUCTURATION = 'ELIGIBILITE_RESTRUCTURATION',
    ELIGIBILITE_DUREE = 'ELIGIBILITE_DUREE',
    NATURE_PRET_NON_REPRIS = 'NATURE_PRET_NON_REPRIS',
    STRUCTURE_FINANCEMENT = 'STRUCTURE_FINANCEMENT',
    NB_CREDITS_RENOUVELABLES_REPRIS = 'NB_CREDITS_RENOUVELABLES_REPRIS',
    DIMENSION_INCONNUE = 'DIMENSION_INCONNUE',
    GENERIC_ERROR_MESSAGE = 'GENERIC_ERROR_MESSAGE',
}

export enum HorsNormeCodeEnum {
    AGE_MAX_FIN_DE_PRET = 'AGE_MAX_FIN_DE_PRET',
    CHAMP_RESTRUCTURATION = 'CHAMP_RESTRUCTURATION',
    DUREE_FINANCEE = 'DUREE_FINANCEE',
    ENDETTEMENT_APRES = 'ENDETTEMENT_APRES',
    FRAIS_DOSSIER_MONTANT = 'FRAIS_DOSSIER_MONTANT',
    FRAIS_DOSSIER_POURCENT = 'FRAIS_DOSSIER_POURCENT',
    GAMME = 'GAMME',
    MONTANT_FINANCE = 'MONTANT_FINANCE',
    MONTANT_TRESORERIE = 'MONTANT_TRESORERIE',
    NB_IMPAYE_IMMO = 'NB_IMPAYE_IMMO',
    PLAFOND_TRESORERIE_POURCENT = 'PLAFOND_TRESORERIE_POURCENT',
    PRET_NON_REPRIS = 'PRET_NON_REPRIS',
    RATIO_HYPOTHECAIRE_MONTANT_PRET = 'RATIO_HYPOTHECAIRE_MONTANT_PRET',
    RATIO_PATRIMOINE = 'RATIO_PATRIMOINE',
    RESTE_A_VIVRE_APRES = 'RESTE_A_VIVRE_APRES',
    TAUX_NOMINAL = 'TAUX_NOMINAL',
    TYPE_BAREME = 'TYPE_BAREME',
    TYPE_CREDIT = 'TYPE_CREDIT',
    TYPE_TAUX = 'TYPE_TAUX',
    PARAMETRE_INCONNU = 'PARAMETRE_INCONNU',
    TAUX_USURE = 'TAUX_USURE',
}

export interface DemandeSimulationRapide {
    gamme: string
    regime: string
    nombreAdulte: number
    nombreEnfant: number
    naissanceEmprunteur: string
    naissanceCoemprunteur?: string
    montantRevenus: number
    montantRestantDu: number
    montantPretsRepris: number
    montantPretsConserves: number
    rac: boolean
    racHypo?: boolean
    montantImpayesCRD?: number
    ancienneteRachatNonHypo?: number
    ancienneteRachatHypo?: number
    montantTresorerie: number
    montantMandat?: number
    dureePret: number
}
