import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { formatCondenseDate } from '@core/common'
import { Montant, Priority, TelephoneSimple, Mail } from '@mmb/ui-components'

import { Facet } from '../Facet'
import { Gamme } from '../Gamme'

import styles from './DossierInformation.module.css'


DossierInformation.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    telephone: PropTypes.string,
    email: PropTypes.string,
  })).isRequired,
  reference: PropTypes.string,
  montant: PropTypes.number,
  statut: PropTypes.string,
  dateModif: PropTypes.string,
  gammeClass: PropTypes.string,
  gammeLabel: PropTypes.string,
  agence: PropTypes.string,
  isPriority: PropTypes.bool,
}

DossierInformation.defaultProps = {
  reference: '',
  montant: -1,
  statut: '',
  dateModif: '',
  gammeClass: '',
  gammeLabel: '',
  agence: '',
  isPriority: false,
}

export function DossierInformation({ entities, reference, montant, statut, dateModif, gammeClass, gammeLabel, agence, isPriority }) {
  return (
    <div className={styles.information__container}>
      <div className={styles.information__personnes}>
        {entities.map(entity => (
          <div key={entity.name}>
            <h3 className={styles.information__personne}>
              {entity.name}
            </h3>
            {(entity.telephone) && (
            <>
              <TelephoneSimple className={styles.information__personne__icon} />
              <span className={styles.information__personne__communication}> {entity.telephone} </span>
            </>
            )}
            {(entity.email) && (
            <>
              <Mail className={styles.information__personne__icon} />
              <span className={styles.information__personne__communication}> {entity.email} </span>
            </>
            )}
          </div>
        ))}
      </div>
      <div className={styles.information__reference}>Réf: {reference}</div>
      {montant && (
        <Montant
          decimal
          separator
          value={montant}
          className={styles.information__montant}
          wholeClassName={styles.information__whole}
          separatorClassName={styles.information__separator}
          decimalClassName={styles.information__decimal}
        />)}
      {statut && (
        <div className={styles.information__statut} data-testid="statut">
          {statut}
        </div>
      )}
      {dateModif && (
        <div className={styles.information__date}>{formatCondenseDate(dateModif)}</div>
      )}
      {gammeClass && (
        <Gamme className={cn(gammeClass, styles.information__gamme)}>
          {gammeLabel}
        </Gamme>
      )}
      <Facet className={styles.information__agence}>{agence}</Facet>
      {isPriority && (
        <div className={styles.information__priority}><Priority className={styles.information__icon} /> Priority</div>
      )}
    </div>
  )
}
