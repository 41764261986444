import * as CryptoJS from 'crypto-js'
import { getConsent } from './consent'
import { smartProfileTrackWithParams, smartProfileTrackOnClick } from '../smartprofile'


export function trackOnChange(page: string, login: string) {
  if (localStorage.getItem('sp_track') !== page) {
    const uuid = generateUUID(login)
    const loginParam = `&login=${uuid}`
    const titleParam = `&_title=${page}`
    const trackParams = [loginParam, titleParam]
    smartProfileTrackWithParams(trackParams, getConsent())
    localStorage.setItem('sp_track', page)
  }
}

export function trackOnClick(buttonLabel: string, clicFrom: string, clicTo: string) {
  smartProfileTrackOnClick('N', buttonLabel, clicFrom, clicTo, getConsent())
}

export enum FromPage {
  ACCUEIL = 'accueil',
  SAISIE = 'saisie',
  SIMULATION = 'saisie - simulation',
  DOSSIERS = 'dossiers',
  DETAIL = 'detail dossier',
  USERS = 'utilisateurs',
  SIMULATION_RAPIDE = 'simulation rapide'
}

export function generateUUID(login) {
  const hashedLogin = CryptoJS.SHA256(login)
  return hashedLogin.toString(CryptoJS.enc.Hex).substring(0, 50)
}
