import { gql, useQuery } from '@core/graphql'

import { GAMMES, getStatutLabel } from '@partenaire/frontend-common'
import { CommunicationType, Emprunteur, SignatureType } from '@partenaire/common'
import { capitalize } from '@core/common'

export const GET_DOSSIER_INFORMATIONS = gql`
query getDossierInformations($id: String!) {
  partenaire_dossierTransmis(id: $id) {
    id
    reference
    agence
    historique {
      statut
      dateModif
    }
    dateModification
    montantFinance
    gamme
    emprunteurs {
      id
      identite {
        nom
        prenom
      }
      communications {
        value
        type
      }
    }
    offres {
      offreEmprunteur {
        signatureType
      }
      offreCoemprunteur {
        signatureType
      }
    }
    isPriority
  }
}
`

const formatEmprunteur = (emprunteur: Emprunteur) => {
  const { prenom, nom } = emprunteur.identite
  return {
    id: emprunteur.id,
    prenom,
    nom,
    telephone: getTelephone(emprunteur),
    email: getEmail(emprunteur),
    fullName: (prenom && nom) ? `${capitalize(prenom)} ${nom.toUpperCase()}` : null,
  }
}

export function getTelephone(emprunteur: Emprunteur) {
  const mobile = emprunteur.communications?.find(communication => communication.type === CommunicationType.MOBILE)?.value
  const domicile = emprunteur.communications?.find(communication => communication.type === CommunicationType.DOMICILE)?.value
  return mobile ?? domicile
}

export function getEmail(emprunteur: Emprunteur) {
  return emprunteur.communications?.find(communication => communication.type === CommunicationType.EMAIL)?.value
}

export function useDossierInformations(id) {
  const {
    data = {},
    loading,
  } = useQuery(GET_DOSSIER_INFORMATIONS, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
    variables: { id },
  })

  if (loading) return { loading }
  // eslint-disable-next-line camelcase
  const partenaireDossierTransmis = data?.partenaire_dossierTransmis
  if (!partenaireDossierTransmis || !partenaireDossierTransmis?.emprunteurs) return {}
  const [emprunteur, coEmprunteur] = partenaireDossierTransmis.emprunteurs
  const offres = partenaireDossierTransmis.offres

  const dataWithoutHistorique = {
    loading,
    id: partenaireDossierTransmis?.id,
    agence: partenaireDossierTransmis?.agence,
    gamme: GAMMES[partenaireDossierTransmis?.gamme],
    emprunteur: emprunteur ? formatEmprunteur(emprunteur) : null,
    coEmprunteur: coEmprunteur ? formatEmprunteur(coEmprunteur) : null,
    montant: partenaireDossierTransmis?.montantFinance,
    isPriority: partenaireDossierTransmis?.isPriority,
    reference: partenaireDossierTransmis?.reference,
  }

  if (partenaireDossierTransmis?.historique?.length === 0) {
    return dataWithoutHistorique
  }

  const hasOneSignatureElectronique
    = loading ? false : [offres?.offreEmprunteur, offres?.offreCoemprunteur].some(offre => offre?.signatureType === SignatureType.ELECTRONIQUE)
  const hasOneSignatureManuelle
    = loading ? false : [offres?.offreEmprunteur, offres?.offreCoemprunteur].some(offre => offre?.signatureType === SignatureType.MANUSCRITE)

  const hasSignatureElectronique = (!hasOneSignatureElectronique && !hasOneSignatureManuelle) ? undefined : hasOneSignatureElectronique

  let dateModif = null
  let statut
  if (partenaireDossierTransmis?.historique) {
    [{ dateModif, statut }] = partenaireDossierTransmis.historique
  }

  const informationStatut = getStatutLabel(partenaireDossierTransmis?.gamme, statut, hasSignatureElectronique)

  return {
    ...dataWithoutHistorique,
    dateModif,
    statut: informationStatut,
    loading,
  }
}
