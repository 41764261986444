import React, { useState, useEffect } from 'react'
import { bool } from 'prop-types'

import { Button, Folder, Telechargement, Arrow } from '@mmb/ui-components'
import { Confirmation } from '@partenaire/frontend-ui'
import { useNavigate } from '@core/routing'
import { toast } from '@core/ui'
import { ListPiecesNecessaires, UploadCard } from '@core/partenaire-ui'
import { unstable_useBlocker as useBlocker } from 'react-router-dom'

import { DetailLayout } from '../../Detail/DetailLayout'
import { CATEGORIES_PIECE } from '../../../utils'

import { useGetTransferts, useLiasse } from '../Liasse.hooks'

import styles from './Pieces.module.css'

import { List } from '../DocumentList'
import { DocumentCategorie } from '../../../model/transfert/CategorieUI.model'

export function Pieces() {
  const { liasseUI, loading: loadingGetLiasse } = useLiasse()
  const { transfertsUI, loading: loadingGetTransfert, id, hasPermissionEnvoiPieces } = useGetTransferts()
  const [showList, setShowList] = useState<DocumentCategorie>()
  const blocker = useBlocker(liasseUI?.isTransferable() || false)
  const navigate = useNavigate()

  const [showConfirmEnvoi, setShowConfirmEnvoi] = useState(false)
  const [navigateUrl, setNavigateUrl] = useState<string>()

  const FILES_UPLOAD_LIMIT = 100

  useEffect(() => {
    if (navigateUrl && !liasseUI?.isTransferable()) {
      navigate(navigateUrl)
    }
  },
  [navigateUrl, liasseUI?.isTransferable()])

  const modalTransfert = {
    close: () => setShowConfirmEnvoi(false),
    open: () => setShowConfirmEnvoi(true),
  }
  const titlePage = transfertsUI?.isEnvoiComplementaire() ? 'Envoi des pièces complémentaires' : 'Envoi des pièces'

  const loading = loadingGetLiasse || loadingGetTransfert
  const transfertDisabled = loading || !liasseUI?.isTransferable(transfertsUI?.isEnvoiComplementaire())

  const modal = {
    onClose: () => blocker.reset && blocker.reset(),
    onValidate: () => blocker.proceed && blocker.proceed(),
  }

  const transfertOkToast = () => toast({
    message: transfertsUI!.isEnvoiComplementaire()
      ? 'Votre envoi complémentaire a bien été transmis en analyse.' : 'Le dossier a bien été envoyé en analyse.',
    type: 'SUCCESS',
  })

  const transfertErrorToast = () => toast({
    message: 'L\'envoi des pièces pour étude a échoué, veuillez essayer ultérieurement',
    type: 'ERROR',
  })

  const toManyFilesToast = () => toast({
    message: 'Nombre de fichiers trop important (100 Max), merci de réaliser un deuxième transfert',
    type: 'ERROR',
  })

  const onClickSend = () => {
    if (liasseUI!.countCurrentLiasseDocuments() > FILES_UPLOAD_LIMIT) {
      toManyFilesToast()
    } else {
      modalTransfert.open()
    }
  }

  const envoiButton = !showList && (
    <Button
      rightIcon={Arrow}
      aria-label="Envoyer pour étude"
      disabled={transfertDisabled || !hasPermissionEnvoiPieces}
      onClick={onClickSend}
    >
      Envoyer pour étude
    </Button>
  )
  return loading ? null : (
    <DetailLayout id={liasseUI?.props.id!} title={titlePage} right={envoiButton}>
      <Confirmation
        label="Confirmation pour quitter la page sans envoi pour étude"
        isOpen={blocker.state === 'blocked'}
        onClose={modal.onClose}
        onValidate={modal.onValidate}
        icone={Telechargement}
        title="Envoi pour étude"
      >
        <p>Il vous reste des documents à envoyer pour étude.<br />
          Êtes-vous sûr de vouloir quitter la page ?
        </p>
      </Confirmation>
      <Confirmation
        label="Confirmation d'envoi d'un dossier complet"
        isOpen={showConfirmEnvoi}
        onClose={modalTransfert.close}
        onValidate={async () => {
          modalTransfert.close()
          await liasseUI?.transfert()
            .then(() => {
              transfertOkToast()
              setNavigateUrl(`/app/dossiers/${id}/pieces/historique`)
            })
            .catch(transfertErrorToast)
          await transfertsUI?.reload()
        }}
        icone={Folder}
        title="Dossier complet"
      >
        <p>
          Seuls les dossiers complets seront pris en charge.<br />
          Avez-vous bien déposé tous les documents ?
        </p>
      </Confirmation>
      <div
        aria-label="Envoi des pièces"
        className={styles.pieces__table}
      >
        {showList ? (
          <List
            categorieLabel={CATEGORIES_PIECE.find(c => c.value === showList)?.label!}
            popoverItems={CATEGORIES_PIECE.find(c => c.value === showList)?.popoverItems!}
            documentUIs={liasseUI?.props.documentsUI?.findDocumentByCategorie(showList) as any[]}
            getDownloadDocumentURL={(documentId: string) => liasseUI?.getDownloadDocumentURL((window as any).GATSBY_API_URL, documentId)}
            deleteDocument={(documentId: string) => liasseUI?.deleteDocument(documentId)}
            goBackFunction={() => setShowList(undefined)}
          />
        ) : (
          <>
            <MessageInformatif firstSend={transfertsUI!.isEnvoiComplementaire()} />
            <p className={styles.pieces__titreComment}>
              Ajoutez un commentaire
            </p>
            <div className={styles.pieces__comment}>
              <textarea
                name="comment"
                aria-label="Commentaire"
                className={styles.pieces__textComment}
                rows={5}
                placeholder="Saisir tout commentaire ou explication sur le dossier"
                value={liasseUI!.props.commentaire}
                onChange={evt => liasseUI?.updateCommentaireProps(evt.target.value)}
              />
            </div>
            <div className={styles.pieces__cards}>
              {liasseUI?.findUploadableDocumentsByCategoriePiece(transfertsUI!.isEnvoiComplementaire()).map(({ documents, categoriePiece }) => (
                <UploadCard
                  key={`Catégorie ${categoriePiece.label}`}
                  documents={documents || []}
                  uploadDocument={async (file: File) => liasseUI?.uploadDocument(file, categoriePiece.value)}
                  categorie={categoriePiece}
                  setShowList={() => setShowList(categoriePiece.value)}
                  firstSend={transfertsUI!.isEnvoiComplementaire()}
                  refetchDocuments={() => liasseUI!.refetchDocuments()}
                  pieces={liasseUI?.props.piecesUI?.getPiecesManquanteByCategorie(categoriePiece.value) || []}
                  hasPermissionEnvoiPieces={hasPermissionEnvoiPieces}
                />
              ))}
            </div>
            {transfertsUI!.isEnvoiComplementaire() && <ListPiecesNecessaires pieces={liasseUI?.props.piecesUI?.getPiecesValide() || []} />}
          </>
        )}
      </div>
    </DetailLayout>
  )
}

MessageInformatif.propTypes = {
  firstSend: bool.isRequired,
}

function MessageInformatif({ firstSend }) {
  return (
    <p className={styles.pieces__messageInformatif}>
      {firstSend ? (
        <>
          Ajoutez tous les justificatifs scannés par catégorie en vous assurant qu’ils soient lisibles puis cliquez sur Envoyer pour étude.
          <br />Les documents seront consultables dans l’historique des envois.
        </>
      ) : (
        <>
          Afin de permettre une plus grande rapidité de traitement, nous vous remercions de nous adresser les pièces manquantes
          <span className={styles.pieces__messageInformatifBold}> en un seul envoi.</span>
        </>
      )}
    </p>
  )
}
