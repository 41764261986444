import React, { useCallback, useState, useEffect } from 'react'
import cn from 'classnames'
import { func, string, bool, array, shape, arrayOf } from 'prop-types'

import { Chevron } from '@mmb/ui-components'
import { FileDock, toast } from '@core/ui'

import { PopoverCategorie } from '../PopoverCategorie'

import { ListPiecesManquantes } from '../ListPieces'
import { StatutPiece } from './StatutPiece'
import styles from './UploadCard.module.css'

UploadCard.propTypes = {
  categorie: shape({
    label: string,
    value: string,
    icone: func,
    contour: bool,
  }).isRequired,
  setShowList: func.isRequired,
  documents: array.isRequired,
  firstSend: bool.isRequired,
  uploadDocument: func.isRequired,
  refetchDocuments: func.isRequired,
  pieces: arrayOf(shape({
    categorie: string,
    libelle: string.isRequired,
    finalite: string.isRequired,
  })),
  hasPermissionEnvoiPieces: bool.isRequired,
}

UploadCard.defaultProps = {
  pieces: [],
}

const GENERIC_ERROR_TEXT = 'L\'envoi du fichier a été interrompu, veuillez essayer à nouveau.'
const ERROR_TEXTS = new Map<string, string>([
  ['DOCUMENT_MEDIATYPE_MISMATCH', 'Le document à télécharger a un format incorrect (format fourni différent du contenu).'],
  ['DOCUMENT_EMPTY', 'Le document à télécharger est vide.'],
  ['DOCUMENT_UNRECOGNIZED', 'Fichier incorrect.'],
])

const getErrorText = (error?: string) : string => {
  return error ? ERROR_TEXTS.get(error) || GENERIC_ERROR_TEXT : GENERIC_ERROR_TEXT
}

export function UploadCard({
  categorie, firstSend, setShowList, documents, uploadDocument, refetchDocuments, pieces, hasPermissionEnvoiPieces,
}) {
  const { icone: Icone, contour, popoverItems } = categorie
  const hasPieces = !!documents?.length
  const [nbLoadingFiles, setNbLoadingFiles] = useState(0)
  const [progressedFiles, setProgressedFiles] = useState(0)

  useEffect(() => {
    if (nbLoadingFiles > 0 && progressedFiles === nbLoadingFiles) {
      setNbLoadingFiles(0)
      setProgressedFiles(0)
      refetchDocuments()
    }
  }, [progressedFiles, nbLoadingFiles])

  const showErrorToast = (fileName, fileError) => {
    toast({
      message: (
        <div className={styles.uploadCard__toastError}>
          {fileError}<br />
          <div className={styles.uploadCard__toastFilename}>{fileName}</div>
        </div>
      ),
      type: 'ERROR',
    })
  }

  const callUpload = async (file: File) => {
    uploadDocument(file).then(async (response: Response) => {
      if (!response.ok) {
        response.json()
          .then(({ error }) => showErrorToast(file.name, getErrorText(error.message)))
          .catch(() => showErrorToast(file.name, getErrorText()))
      }
      setProgressedFiles(files => files + 1)
    })
  }

  const isValidFile = file => {
    const fileError = checkFileError(file)
    const isValid = !fileError
    if (!isValid) {
      showErrorToast(file.name, fileError)
      setProgressedFiles(files => files + 1)
    }
    return isValid
  }

  const onFilesDrop = useCallback(async (newDroppedFiles: File[]) => {
    const files = Array.from(newDroppedFiles)
    setNbLoadingFiles(files.length)
    files.filter(isValidFile).forEach(callUpload)
  }, [])

  return (
    <div
      className={cn(styles.uploadCard__card, { [styles.uploadCard__cardComplementaire]: firstSend })}
      aria-label={`Cadre des pièces de la catégorie ${categorie.label}`}
    >
      <div className={styles.uploadCard__cardHeader}>
        <Icone contour={contour} inline className={styles.uploadCard__icone} />
        <div className={cn({ [styles.uploadCard__enteteComplementaire]: !firstSend })}>
          <div className={styles.uploadCard__title}>
            <span className={styles.uploadCard__titleLibelle}>{categorie.label}</span>
            {!firstSend && <PopoverCategorie label={categorie.label} items={popoverItems} />}
          </div>

          <StatutPiece
            label={categorie.label}
            hasPieces={hasPieces}
            nbPieces={documents.length}
            progressedFiles={progressedFiles}
            nbLoadingFiles={nbLoadingFiles}
            showNoDoc={firstSend}
            loading={false}
          />
        </div>

        {hasPieces && (
          <button
            data-testid="setShowListButton"
            aria-label={`Accéder à la liste des pièces de la catégorie ${categorie.label}`}
            className={styles.uploadCard__link}
            onClick={setShowList}
          >
            <Chevron inline className={styles.uploadCard__chevron} />
          </button>
        )}
      </div>
      {firstSend && <ListPiecesManquantes pieces={pieces} />}
      {hasPermissionEnvoiPieces && <FileDock name="files" onUpload={onFilesDrop} multiple showFormat={false} className={styles.uploadCard__fileDock} />}
    </div>
  )
}

const maxSize = 25 * 1048576

export function checkFileError(file) {
  let error = ''
  const fileTypes = ['image/png', 'image/jpeg', 'application/pdf']
  if (!fileTypes.includes(file.type)) {
    error = 'Format du fichier non accepté.'
  } else if (file.size > maxSize) {
    error = 'Taille de fichier trop lourd (25Mo max), envoyer votre document en plusieurs fois.'
  }
  return error
}
