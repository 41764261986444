import React from 'react'
import { SwitchArrows, Mail, TelephoneSimple } from '@mmb/ui-components'
import { string, bool } from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { FromPage, trackOnClick } from '@core/analytics'

import { Separator, Facet, VerticalSteps, VerticalStep } from '@core/partenaire-ui'
import { Gamme, WarningPopIn, RoundButton } from '@partenaire/frontend-ui'
import { GAMMES } from '@partenaire/frontend-common'

import styles from './Filigrane.module.css'
import { useEmprunteursNames, useFiligrane, useEmprunteursCommunications } from './Filigrane.hooks'
import { useGamme } from '../Gamme'

Filigrane.propTypes = {
  gamme: string,
  hasCoEmprunteur: bool,
}
Filigrane.defaultProps = {
  gamme: 'UNSECURED',
  hasCoEmprunteur: false,
}

export function Filigrane({ gamme, hasCoEmprunteur }) {
  const filigrane = useFiligrane()
  const { changeGamme, isUpdatingGamme } = useGamme()
  const { emprunteurName, coEmprunteurName } = useEmprunteursNames()
  const { emprunteurEmail, emprunteurMobile, emprunteurDomicile, coEmprunteurEmail, coEmprunteurMobile, coEmprunteurDomicile }
  = useEmprunteursCommunications(hasCoEmprunteur)
  const { value: gammeType = '', label: gammeLabel = '' } = GAMMES[gamme]

  return isEmpty(filigrane.steps) ? null : (
    <>
      <div
        className={classNames({
          [styles.filigrane__infosDossierOneEmprunteur]: !!hasCoEmprunteur,
          [styles.filigrane__infosDossierTwoEmprunteurs]: !hasCoEmprunteur,
        })}
      >
        <div className={styles.filigrane__personnes}>
          <h3 className={styles.filigrane__personne}>
            {emprunteurName}
          </h3>
          <TelephoneSimple className={styles.filigrane__personne__icon} />
          <span className={styles.filigrane__personne__communication}> {emprunteurMobile || emprunteurDomicile || 'Non renseigné'} </span>
          <Mail className={styles.filigrane__personne__icon} />
          <span className={styles.filigrane__personne__communication}> {emprunteurEmail || 'Non renseigné'} </span>


          {coEmprunteurName && (

          <>
            <h1 className={styles.filigrane__personne}>
              {coEmprunteurName}
            </h1>
            <TelephoneSimple className={styles.filigrane__personne__icon} />
            <span className={styles.filigrane__personne__communication}> {coEmprunteurMobile || coEmprunteurDomicile || 'Non renseigné'} </span>
            <Mail className={styles.filigrane__personne__icon} />
            <span className={styles.filigrane__personne__communication}> {coEmprunteurEmail || 'Non renseigné'} </span>
          </>
          )}
        </div>
        <div className={styles.filigrane__gammeWrapper}>
          <Gamme type={gammeType} className={styles.filigrane__gamme}>
            {gammeLabel}
          </Gamme>
          <WarningPopIn
            onConfirm={() => {
              trackOnClick('bouton switch gamme - filigrane', FromPage.SAISIE, gamme)
              changeGamme()
            }}
            label="Changement de gamme"
            title="Changement de gamme"
            warningLine={`Souhaitez-vous basculer votre dossier en ${gamme === 'SECURED' ? 'sans garantie' : 'hypothécaire'} ?`}
            warningLineBold="Merci de confirmer."
            loading={isUpdatingGamme}
            confirmText="Confirmer"
            loadingText="Changement en cours"
          >
            <RoundButton
              type="button"
              className={styles.filigrane__switchGammeButton}
            >
              <SwitchArrows className={styles.filigrane__switchGammeButtonIcon} />
            </RoundButton>
          </WarningPopIn>
        </div>
        <Facet className={styles.filigrane__agence}>{filigrane?.apporteur}</Facet>
      </div>
      <Separator className={styles.filigrane__separator} />
      <VerticalSteps data-testid="verticalsteps" className="">
        {filigrane.steps.map(step => {
          return (
            <VerticalStep
              key={step.name}
              labelClassName={styles.filigrane__label}
              label={step.getLabel()}
              anchor={step.getAnchor()}
              state={step.getState()}
              className=""
              contentClassName=""
              nextStateStep="success"
              barClassName={classNames(
                styles.filigrane__bar,
                { [styles.filigrane__barValide]: step.getState() !== 'notStarted' },
              )}
            >
              <span
                className={classNames({
                  [styles.filigrane__message]: step.showErrors(),
                  [styles.filigrane__messageNotStarted]: !step.showErrors(),
                })}
              />
            </VerticalStep>
          )
        })}
      </VerticalSteps>
    </>
  )
}
