import { Family, DocumentSigne, Avatar, EllipsisDots, Euro, House, RegroupementPrets, Wallet } from '@mmb/ui-components'
import { CategoriePieceUI, DocumentCategorie } from '../model/transfert/CategorieUI.model'

export const VALIDATED_PIECE = 'VALIDATED_PIECE'

export const CATEGORIES_PIECE: CategoriePieceUI[] = [{
  value: DocumentCategorie.DEMANDE_DE_PRET,
  label: 'Synthèse dossier',
  icone: DocumentSigne,
  contour: true,
  popoverItems: ['Synthèse dossier', 'Dossier client', 'Note de présentation'],
}, {
  value: DocumentCategorie.EN_COURS,
  label: 'Encours',
  icone: RegroupementPrets,
  popoverItems: ['Offres / TA / décomptes de prêts', 'Tout autre document relatif aux prêts'],
}, {
  value: DocumentCategorie.IDENTITE,
  label: 'Identité',
  icone: Avatar,
  popoverItems: ['CNI ou tout autre document relatif à l\'identité', 'Livret de famille', 'Documents relatifs au divorce', 'Justificatif de domicile'],
}, {
  value: DocumentCategorie.PATRIMOINE_IMMOBILIER,
  label: 'Patrimoine immobilier',
  icone: House,
  popoverItems: ['Titre de propriété / attestation notariée de propriété', 'Fiche descriptive du bien + photos', 'Tableau du patrimoine Locatif'],
}, {
  value: DocumentCategorie.REVENUS,
  label: 'Revenus',
  icone: Euro,
  popoverItems: ['Bulletins de salaire / relevés de pension', 'Dernier IRPP', 'Tout autre document relatif aux revenus (Déclaration 2044, 2042, 2035, etc...)'],
}, {
  value: DocumentCategorie.ASSURANCE,
  label: 'Assurance',
  icone: Family,
  popoverItems: ['Délégation', 'Renonciations'],
}, {
  value: DocumentCategorie.COMPTES_BANCAIRES,
  label: 'Comptes bancaires',
  icone: Wallet,
  popoverItems: ['Relevés de tous les comptes bancaires (3 derniers mois)',
    'RIB de Prélèvement', 'Tout autre document relatif aux comptes ou mouvements bancaires.'],
}, {
  value: DocumentCategorie.AUTRE,
  label: 'Autre',
  icone: EllipsisDots,
  popoverItems: ['Autres documents'],
},
]
