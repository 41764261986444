import React from 'react'
import cn from 'classnames'
import { node, string } from 'prop-types'

import styles from './Gamme.module.css'
import { Facet } from '../Facet'

Gamme.propTypes = {
  children: node,
  className: string,
}

Gamme.defaultProps = {
  children: null,
  className: '',
}

export function Gamme({ children, className }) {
  const styleGamme = cn(
    styles.gamme,
    className,
  )

  return (
    <Facet className={styleGamme}>{children}</Facet>
  )
}
