import React, { useState } from 'react'


import { FromPage, trackOnChange, trackOnClick } from '@core/analytics'
import { Button, Input, Radio } from '@mmb/ui-components'

import { Panel, Select, Separator } from '@core/partenaire-ui'
import { InputMontant } from '@partenaire/frontend-ui'
import { useIdentite } from '@core/partenaire-profil'
import { DATE_DD_MM_YYYY_FR, parseDate } from '@core/common'
import { DemandeSimulationRapide, Gamme } from '@partenaire/common'
import styles from './SaisieSimulateurRapide.module.css'
import { GAMMES_OPTIONS, parseMontant } from '../../utils'
import { ResultatSimulationRapide } from './ResultatSimulationRapide'
import { useSimulationRapide } from './SimulationRapide.hook'
import { GAMME_OPTION_SECURED, GAMME_OPTION_UNSECURED, GammeOption, REGIME_OPTION_LCC, REGIME_OPTION_LS2, REGIME_OPTIONS } from '../../utils/const'
import { SimulationStatus } from '../Simulation/const'
import { SimulationCard } from '../Simulation/SimulationCard'
import { ChangeGammeProps } from '../Gamme'

interface SimulationForm {
  gamme: GammeOption
  regime: { label: string, value: string }
  nombreAdulte: number
  nombreEnfant: number
  naissanceEmprunteur?: string
  naissanceCoemprunteur?: string
  montantRevenus: number
  montantRestantDu: number
  montantPretsRepris: number
  montantPretsConserves: number
  rac: boolean
  racHypo: boolean
  montantImpayesCRD: number
  ancienneteRachatNonHypo: number
  ancienneteRachatHypo: number
  montantTresorerie: number
  montantMandat: number
  dureePret: number
}

export function SaisieSimulateurRapide() {
  const { identifiant } = useIdentite()
  const { getSimulationRapide, simulation, loading, error: queryError } = useSimulationRapide()
  trackOnChange('Simulateur rapide', identifiant!)

  const [isShowSimulation, setIsShowSimulation] = useState<boolean>(false)
  const [buttonLabel, setButtonLabel] = useState<string>('Lancer la simulation rapide')
  const [formState, setFormState] = useState<SimulationForm>({
    gamme: GAMME_OPTION_SECURED,
    regime: REGIME_OPTION_LS2,
    nombreAdulte: 0,
    nombreEnfant: 0,
    naissanceEmprunteur: undefined,
    naissanceCoemprunteur: undefined,
    montantRevenus: 0,
    montantRestantDu: 0,
    montantPretsRepris: 0,
    montantPretsConserves: 0,
    rac: false,
    racHypo: false,
    montantImpayesCRD: 0,
    ancienneteRachatNonHypo: 0,
    ancienneteRachatHypo: 0,
    montantTresorerie: 0,
    montantMandat: 0,
    dureePret: 0,
  })
  const [errors, setErrors] = useState({
    montantRestantDuError: false,
    montantMandatError: false,
    montantPretsConservesError: false,
    nombreEnfantError: false,
    nbAdulteError: false,
    naissanceEmprunteurError: false,
    naissanceCoemprunteurError: false,
    revenusError: false,
    tresorerieError: false,
    dureePretError: false,
    ancienneteRachatHypo: false,
    ancienneteRachatNonHypo: false,
  })

  const handleChange = (field: string, value: any) => {
    setFormState({
      ...formState,
      [field]: value,
    })
  }

  // si on choisit une gamme 'Sans garantie', le régime doit être 'LCC' et le <Select> disabled
  const setGamme = (gammeValue: GammeOption) => {
    if (gammeValue === GAMME_OPTION_UNSECURED) {
      setFormState({
        ...formState,
        regime: REGIME_OPTION_LCC,
        gamme: gammeValue,
        racHypo: false,
      })
    } else {
      setFormState({
        ...formState,
        gamme: gammeValue,
      })
    }
  }

  const resetRAC = {
    ancienneteRachatNonHypo: 0,
    ancienneteRachatHypo: 0,
    montantImpayesCRD: 0,
  }

  const toSimulationRapideQuery = (form: SimulationForm) : { demandeSimulationRapide: DemandeSimulationRapide } => ({ demandeSimulationRapide: {
    gamme: form.gamme.value || form.gamme[0].value,
    regime: form.regime.value || form.regime[0].value,
    nombreAdulte: form.nombreAdulte,
    naissanceEmprunteur: parseDate(form.naissanceEmprunteur, DATE_DD_MM_YYYY_FR)!.toISOString(),
    ...(form.naissanceCoemprunteur ? { naissanceCoemprunteur: parseDate(form.naissanceCoemprunteur, DATE_DD_MM_YYYY_FR)!.toISOString() } : {}),
    montantRevenus: form.montantRevenus,
    montantPretsRepris: form.montantPretsRepris,
    nombreEnfant: form.nombreEnfant,
    montantRestantDu: form.montantRestantDu,
    montantPretsConserves: form.montantPretsConserves,
    rac: form.rac,
    ...(form.rac ? {
      racHypo: form.racHypo,
      ...(form.racHypo ? {
        ancienneteRachatHypo: form.ancienneteRachatHypo,
      } : {
        montantImpayesCRD: form.montantImpayesCRD,
        ancienneteRachatNonHypo: form.ancienneteRachatNonHypo,
      }),
    } : {}),
    montantTresorerie: form.montantTresorerie,
    dureePret: form.dureePret,
    montantMandat: form.montantMandat,
  } })

  const defaultRacHypo = formState.gamme.value === Gamme.SECURED

  const isNumber = (value: any) : boolean => typeof value === 'number' && Number.isFinite(value)

  const onSave = async e => {
    trackOnClick('button simulation rapide', FromPage.SIMULATION_RAPIDE, 'lancement simulation')
    e.preventDefault()
    try {
      const newErrors = {
        montantRestantDuError: !isNumber(formState.montantRestantDu),
        montantMandatError: !isNumber(formState.montantMandat),
        montantPretsConservesError: !isNumber(formState.montantPretsConserves),
        nombreEnfantError: !Number.isInteger(formState.nombreEnfant),
        nbAdulteError: formState.nombreAdulte === 0 || !Number.isInteger(formState.nombreAdulte),
        revenusError: formState.montantRevenus === 0,
        dureePretError: formState.dureePret === 0,
        tresorerieError: formState.montantTresorerie === 0,
        naissanceEmprunteurError: Boolean(!formState.naissanceEmprunteur || !parseDate(formState.naissanceEmprunteur, DATE_DD_MM_YYYY_FR)),
        naissanceCoemprunteurError: Boolean(formState.naissanceCoemprunteur && !parseDate(formState.naissanceCoemprunteur, DATE_DD_MM_YYYY_FR)),
        ancienneteRachatHypo: formState.rac && formState.racHypo && formState.ancienneteRachatHypo === 0,
        ancienneteRachatNonHypo: formState.rac && !formState.racHypo && formState.ancienneteRachatNonHypo === 0,
      }
      setErrors(newErrors)
      if (!Object.values(newErrors).some(error => error)) {
        setIsShowSimulation(true)
        await getSimulationRapide({
          variables: toSimulationRapideQuery(formState),
        })
        setButtonLabel('Actualiser la simulation rapide')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const gammeProps: ChangeGammeProps = {
    gamme: formState.gamme.value,
    changeGamme: () => setGamme(formState.gamme.value === Gamme.SECURED ? GAMME_OPTION_UNSECURED : GAMME_OPTION_SECURED),
    isUpdatingGamme: loading,
  }

  return (
    <div className={styles.saisie}>
      <Panel className={styles.saisie__panel}> </Panel>
      <div className={styles.saisie__main}>
        <h2 className={styles.saisie__title}>
          Saisie de simulation rapide
        </h2>
        <Separator />
        <div>
          <form onSubmit={onSave}>
            <div className={styles.saisie__formulaire}>
              <div className={styles.saisie__columns}>
                <div className={styles.saisie__column}>
                  <Select
                    labelClassName={styles.saisie__select__label}
                    options={GAMMES_OPTIONS}
                    onChange={setGamme}
                    name="gamme"
                    label="Gamme"
                    value={formState.gamme}
                    required
                  />
                  <Input
                    fieldClassName={errors.nbAdulteError ? styles.inputError : ''}
                    name="nombreAdulte"
                    label="Nombre d'adultes dans le foyer"
                    labelClassName={styles.saisie__input__label}
                    value={formState.nombreAdulte.toString()}
                    onChange={e => handleChange('nombreAdulte', parseInt(e.target.value, 10))}
                    type="number"
                    required
                  />
                  <Input
                    fieldClassName={errors.naissanceEmprunteurError ? styles.inputError : ''}
                    type="text"
                    name="naissanceEmprunteur"
                    label="Date de naissance emprunteur"
                    onChange={e => handleChange('naissanceEmprunteur', (e.target.value))}
                    value={formState.naissanceEmprunteur}
                    placeholder="JJ/MM/AAAA"
                  />
                  <InputMontant
                    fieldClassName={errors.revenusError ? styles.inputError : ''}
                    name="montantRevenus"
                    label="Revenus du foyer (y compris allocations)"
                    value={formState.montantRevenus.toString()}
                    onChange={({ target }) => handleChange('montantRevenus', parseMontant(target.value))}
                    type="number"
                    unit="€"
                    unitClassName={styles.saisie__montant__unite}
                    required
                  />
                  <InputMontant
                    name="montantPretsRepris"
                    label="Somme des échéances des prêts repris"
                    value={formState.montantPretsRepris.toString()}
                    onChange={({ target }) => handleChange('montantPretsRepris', parseMontant(target.value))}
                    type="number"
                    unit="€"
                    unitClassName={styles.saisie__montant__unite}
                  />
                </div>
                <div className={styles.saisie__column}>
                  <Select
                    labelClassName={styles.saisie__select__label}
                    options={REGIME_OPTIONS}
                    onChange={selectedValue => handleChange('regime', selectedValue)}
                    name="regime"
                    label="Régime LS2 ou LCC"
                    value={formState.regime}
                    required
                    disabled={formState.gamme === GAMME_OPTION_UNSECURED}
                  />
                  <Input
                    fieldClassName={errors.nombreEnfantError ? styles.inputError : ''}
                    name="nombreEnfant"
                    label="Nombre d'enfants de moins de 18 ans à charge"
                    labelClassName={styles.saisie__input__label}
                    value={formState.nombreEnfant.toString()}
                    onChange={e => handleChange('nombreEnfant', (parseInt(e.target.value, 10)))}
                    type="number"
                    required
                  />
                  <Input
                    fieldClassName={errors.naissanceCoemprunteurError ? styles.inputError : ''}
                    type="text"
                    name="naissanceCoemprunteur"
                    label="Date de naissance co-emprunteur"
                    onChange={e => handleChange('naissanceCoemprunteur', (e.target.value))}
                    value={formState.naissanceCoemprunteur}
                    placeholder="JJ/MM/AAAA"
                  />
                  <InputMontant
                    fieldClassName={errors.montantRestantDuError ? styles.inputError : ''}
                    name="montantRestantDu"
                    label="Capital Restant Dû des prêts repris"
                    value={formState.montantRestantDu.toString()}
                    onChange={({ target }) => handleChange('montantRestantDu', parseMontant(target.value))}
                    type="number"
                    unit="€"
                    unitClassName={styles.saisie__montant__unite}
                    required
                  />
                  <InputMontant
                    fieldClassName={errors.montantPretsConservesError ? styles.inputError : ''}
                    name="montantPretsConserves"
                    label="Somme des échéances des prêts conservés"
                    value={formState.montantPretsConserves.toString()}
                    onChange={({ target }) => handleChange('montantPretsConserves', parseMontant(target.value))}
                    type="number"
                    unit="€"
                    unitClassName={styles.saisie__montant__unite}
                    required
                  />
                </div>
              </div>

              <div className={styles.saisie__rac}>
                <div className={styles.saisie__radio__title}>
                  Présence d'un RAC dans les prêts rachetés
                  {formState.gamme.value === Gamme.UNSECURED && formState.rac && ' (non hypo en gamme sans garantie)'}
                </div>
                <div className={styles.saisie__radio}>
                  <Radio
                    className={styles.saisie__radio__label}
                    name="racOui"
                    value="true"
                    label="oui"
                    checked={formState.rac}
                    onChange={() => {
                      setFormState({
                        ...formState,
                        ...resetRAC,
                        rac: true,
                        racHypo: defaultRacHypo,
                      })
                    }}
                  />
                  <Radio
                    className={styles.saisie__radio__label}
                    name="racNon"
                    value="false"
                    label="non"
                    checked={!formState.rac}
                    onChange={() => {
                      setFormState({
                        ...formState,
                        ...resetRAC,
                        rac: false,
                        racHypo: defaultRacHypo,
                      })
                    }}
                  />
                </div>
                {formState.rac && (
                <div className={styles.saisie__radio}>
                  <Radio
                    className={styles.saisie__radio__label}
                    name="RAC"
                    value
                    label="RAC Hypo"
                    checked={formState.racHypo}
                    disabled={formState.gamme.value === Gamme.UNSECURED}
                    onChange={() => {
                      setFormState({
                        ...formState,
                        ...resetRAC,
                        racHypo: true,
                      })
                    }}
                  />
                  <Radio
                    className={styles.saisie__radio__label}
                    name="RAC"
                    value={false}
                    label="RAC non hypo"
                    checked={!formState.racHypo}
                    disabled={formState.gamme.value === Gamme.UNSECURED}
                    onChange={() => {
                      setFormState({
                        ...formState,
                        ...resetRAC,
                        racHypo: false,
                      })
                    }}
                  />
                </div>
                )}
              </div>
              {formState.rac && !formState.racHypo && (
              <div className={styles.saisie__columns}>
                <div className={styles.saisie__column}>
                  <InputMontant
                    fieldClassName={errors.ancienneteRachatNonHypo ? styles.inputError : ''}
                    type="number"
                    name="ancienneteRachatNonHypo"
                    label="Ancienneté rachat non hypo"
                    value={formState.ancienneteRachatNonHypo.toString()}
                    onChange={({ target }) => handleChange('ancienneteRachatNonHypo', parseMontant(target.value))}
                    unit="mois"
                    unitClassName={styles.saisie__montant__unite}
                  />
                </div>
                <div className={styles.saisie__column}>
                  <InputMontant
                    fieldClassName={errors.ancienneteRachatNonHypo ? styles.inputError : ''}
                    id="montantImpayes"
                    type="number"
                    name="montantImpayesCRD"
                    label="CRD rachat non hypo"
                    value={formState.montantImpayesCRD.toString()}
                    onChange={({ target }) => handleChange('montantImpayesCRD', parseMontant(target.value))}
                    unit="€"
                    unitClassName={styles.saisie__montant__unite}
                  />
                </div>
              </div>
              )}
              <div className={styles.saisie__column_solo}>
                {formState.rac && formState.racHypo && (
                <InputMontant
                  fieldClassName={errors.ancienneteRachatHypo ? styles.inputError : ''}
                  name="montantAncienneteRachatHypo"
                  label="Ancienneté rachat hypo"
                  value={formState.ancienneteRachatHypo.toString()}
                  onChange={({ target }) => handleChange('ancienneteRachatHypo', parseMontant(target.value))}
                  type="number"
                  unit="mois"
                  unitClassName={styles.saisie__montant__unite}
                />
                )}
              </div>
              <div className={styles.saisie__columns}>
                <div className={styles.saisie__column}>
                  <InputMontant
                    fieldClassName={errors.tresorerieError ? styles.inputError : ''}
                    name="montantTresorerie"
                    label="Montant de la trésorerie"
                    value={formState.montantTresorerie.toString()}
                    onChange={({ target }) => handleChange('montantTresorerie', parseMontant(target.value))}
                    type="number"
                    unit="€"
                    unitClassName={styles.saisie__montant__unite}
                    required
                  />
                  <InputMontant
                    fieldClassName={errors.dureePretError ? styles.inputError : ''}
                    name="dureePret"
                    label="Durée du prêt"
                    value={formState.dureePret.toString()}
                    onChange={({ target }) => handleChange('dureePret', parseMontant(target.value))}
                    type="number"
                    unit="mois"
                    unitClassName={styles.saisie__montant__unite}
                    required
                  />
                </div>
                <div className={styles.saisie__column}>
                  <InputMontant
                    fieldClassName={errors.montantMandatError ? styles.inputError : ''}
                    name="montantMandat"
                    label="Montant du mandat"
                    value={formState.montantMandat.toString()}
                    onChange={({ target }) => handleChange('montantMandat', parseMontant(target.value))}
                    type="number"
                    unit="€"
                    unitClassName={styles.saisie__montant__unite}
                    required
                  />
                </div>

              </div>
            </div>

            <div className={styles.saisie__validation__button}>
              <Button
                type="submit"
                onClick={() => window.scrollTo({
                  top: 1000,
                  behavior: 'smooth',
                })}
                loading={loading}
              >
                {buttonLabel}
              </Button>
            </div>
            {!Object.values(errors).some(error => error) && isShowSimulation && simulation && !queryError &&
              (<ResultatSimulationRapide
                simulation={simulation}
                loading={loading}
                restantDu={formState.montantRestantDu}
                gammeProps={gammeProps}
              />)}
            {queryError && <SimulationCard state={SimulationStatus.INTERNAL_ERROR} />}
          </form>
        </div>
      </div>
    </div>
  )
}
