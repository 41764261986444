import React from 'react'
import classNames from 'classnames'
import { string } from 'prop-types'

import { DossierInformation } from '@core/partenaire-ui'
import { GAMMES } from '@partenaire/frontend-common'

import { useDossierInformations } from './Information.hooks'

import styles from './Information.module.css'

Information.propTypes = {
  id: string.isRequired,
  className: string,
}

Information.defaultProps = {
  className: '',
}

export function Information({ id, className }) {
  const { error, loading, emprunteur, coEmprunteur, agence, gamme, montant, dateModif, statut, isPriority, reference } = useDossierInformations(id)
  if (loading || error) return null

  const emprunteurs = [{
    name: emprunteur.fullName,
    telephone: emprunteur.telephone,
    email: emprunteur.email,
  }]

  if (coEmprunteur) {
    emprunteurs.push({
      name: coEmprunteur.fullName,
      telephone: coEmprunteur.telephone,
      email: coEmprunteur.email,
    })
  }
  const isHypo = gamme.value === GAMMES.SECURED.value
  const isConso = gamme.value === GAMMES.UNSECURED.value
  return (
    <DossierInformation
      entities={emprunteurs}
      reference={reference}
      montant={montant}
      statut={statut}
      dateModif={dateModif}
      gammeClass={classNames(
        {
          [styles.gammeFacetHypo]: isHypo,
          [styles.gammeFacetConso]: isConso,
        },
      )}
      gammeLabel={gamme.label}
      agence={agence}
      isPriority={isPriority}
    />
  )
}
